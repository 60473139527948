<!-- 视频服务器 -->
<template>
    <div style="background-color: white; height: 99.5%">
    <div style="height: 40px; padding: 5px">
      <el-form size="medium" :inline="true">
        <el-form-item label="">
          <el-input
            placeholder="请输入"
            v-model="videoName"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button  size="medium" type="primary" @click="getVideoList"
            ><Search />  搜索</el-button
          >
        </el-form-item>
          <el-form-item>
       <div>
        <el-button
          size="medium"
          type="success"
          @click="addHandle"
          ><CirclePlus /> 添加</el-button
        >
              <el-popconfirm
                style="margin-left: 10px; padding-top: -10px"
                title="确定批量删除吗"
                @confirm="delHandle(null)"
              >
                <template #reference>
              <el-button
          size="medium"
          type="danger"
          :disabled="delBtnStatu"
          ><Delete /> 批量删除</el-button
        >
                </template>
              </el-popconfirm>
      </div>
        </el-form-item>
      </el-form>
    </div>
    <div
      style="height: 5px; background-color: white; background-color: #e9eef3"
    ></div>
    <!-- 摄像头配置窗口 -->
    <div style="height: calc(100% - 55px)">
      <div style="height: calc(100% - 57px)">
        <el-table
          id="videoTableId"
          :header-cell-style="{ backgroundColor: '#EAEAEA' }"
          ref="videoTableData"
          :data="videoTableData"
          tooltip-effect="dark"
          style="width: 99%; margin: 12px 5px 0 7px"
          height="calc(100vh - 270px)"
          stripe
          @selection-change="videoSelectionChange"
        >
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column prop="videoName" label="名称"> </el-table-column>
          <el-table-column prop="appKey" label="APPKey"> </el-table-column>
          <el-table-column v-if="false" prop="appSecret" label="APPSecret">
          </el-table-column>
          <el-table-column prop="videoIp" label="视频服务器Ip">
          </el-table-column>
          <el-table-column prop="videoPort" label="端口"> </el-table-column>
          <el-table-column prop="status" label="是否启用">
             <template #default="scope">
              <el-tag
                size="small"
                v-if="scope.row.status === '1'"
                type="success"
                >启用</el-tag
              >
              <el-tag
                size="small"
                v-else-if="scope.row.status === '0'"
                type="danger"
              >
                禁用
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="icon" label="通道配置">
            <template #default="scope">
              <el-button             
                    link
                    type="primary"
                    size="small" @click="editChannel(scope.row.id)"
                >通道管理</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="icon" label="操作">
            <template #default="scope">
              <el-button             
                   link
                    type="primary"
                    size="small" @click="editHandle(scope.row.id)"
                >修改</el-button
              >
              <el-divider direction="vertical"></el-divider>
                <el-popconfirm
                  title="这是一段内容确定删除吗？"
                  @confirm="delHandle(scope.row.id)"
                >
                  <template  #reference>
                    <el-button             
                    link
                    type="danger"
                    size="small">删除</el-button>
                  </template>
                </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 底栏分页 -->
        <div style="background-color: white">
          <div style="height: 5px"></div>
          <a style="margin-left: 15px">
            已选 <span>{{ hasSelect }}</span>
            项
          </a>
          <el-pagination
            style="float: right; margin: 0px 20px"
            @size-change="videoSizeChange"
            @current-change="videoCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :current-page="current"
            :page-size="size"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--新增对话框-->
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="550px"
      :before-close="handleClose"
    >
      <el-form
        :model="videoForm"
        :rules="videoFormRules"
        ref="videoForm"
        label-width="110px"
        class="demo-videoForm"
      >
        <el-form-item label="名称">
          <el-input
            v-model="videoForm.videoName"
            placeholder="请输入自定义名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="APPKey" prop="appKey">
          <el-input
            v-model="videoForm.appKey"
            placeholder="请输入平台提供APPKey"
          ></el-input>
        </el-form-item>
        <el-form-item label="APPSecret" prop="appSecret">
          <el-input
            v-model="videoForm.appSecret"
            placeholder="请输入平台提供APPSecret"
          ></el-input>
        </el-form-item>
        <el-form-item label="视频服务器Ip" prop="videoIp">
          <el-input
            v-model="videoForm.videoIp"
            placeholder="请输入视频服务器IP"
          ></el-input>
        </el-form-item>
        <el-form-item label="端口" prop="videoPort">
          <el-input
            v-model="videoForm.videoPort"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入视频服务器端口"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="videoForm.status">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item style="text-align: left">
          <el-button type="primary" size="medium" @click="resetForm('videoForm')"><Refresh/> 重置</el-button>
          <el-button type="primary" size="medium" @click="submitForm('videoForm')"
            ><CircleCheck/> 提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--新增对话框-->
    <el-dialog
      class="ChannelCfg"
      :title="dialogTitle"
       v-model="channelDlg"
      width="80%"
      :destroy-on-close="true"
      :before-close="handleClose1"
    >
      <ChannelCfg :curVideoId="curVideoId"  @showIntallPlugEvent="showIntallPlugEvent"></ChannelCfg>
    </el-dialog>
    <el-dialog
      title="提示"
      v-show="plugDlgVisible"
      width="25%"
      height="2%"
      :before-close="handleClose"
    >
      <span>点击确定下载64位播放插件</span>
      <span class="dialog-footer">
        <el-button @click="plugDlgVisible = false">取 消</el-button>
        <el-button type="primary" @click="installPlug">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>

<script>
import ChannelCfg from "./components/ChannelCfg";
import axios from "@/http/HTTP";
import { ElMessage } from "element-plus";

export default {
  name: "VideoConfig",
  components: {
    ChannelCfg,
  },
  data() {
    var checkoutIP = (rule, value, callback) => {
      const ipRules =
        /^((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9]))$/;
      if (!ipRules.test(value)) {
        callback(new Error("ip不合法"));
      } else {
        callback();
      }
    };
    return {
      videoName: "", //服务器Ip
      plugDlgVisible:false,
      videoTableData: [], //表字段
      multipleSelection: [], //存放多选数据
      delBtnStatu: true,
      //添加视频相关配置
      dialogTitle: "",
      dialogVisible: false, //添加视频配置dialog
      videoForm: {},
      videoFormRules: {
        appKey: [
          { required: true, message: "请输入平台提供APPKey", trigger: "blur" },
        ],
        appSecret: [
          {
            required: true,
            message: "请输入平台提供APPSecret",
            trigger: "blur",
          },
        ],
        videoIp: [
          { required: true, message: "请输入视频服务器IP", trigger: "change" },
          { validator: checkoutIP, trigger: "change" },
        ],
        videoPort: [
          { required: true, message: "请输入视频服务器端口", trigger: "blur" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },

      //通道信息字段
      channelDlg: false,

      // 分页字段
      total: 0,
      size: 10,
      current: 1,
      hasSelect: "0",
      curVideoId: "",
    };
  },
  created() {
    this.getVideoList();
  },
  methods: {
    installPlug(){
      window.open("/static/VideoWebPlugin.exe");
      this.plugDlgVisible = false;
    },
    showIntallPlugEvent()
    {
       this.plugDlgVisible = true;
    },
    //初始化获取所有视频信息
    getVideoList() {
      axios
        .get("/video/getVideoList", {
          params: {
            videoName: this.videoName,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          console.log("this.videoName:",this.videoName,"%%%%%getVideoList:", res.data.records);
          this.videoTableData = res.data.records;
          this.size = res.data.size;

          this.total = res.data.total;
          this.current = res.data.current;
        });
    },
    addHandle() {
      console.log("test dasfdas");
      this.dialogTitle = "添加视频信息";
      this.dialogVisible = true;
    },
    //修改视频信息
    editHandle(id) {
      axios.get("/video/info/" + id).then((res) => {
        this.videoForm = res.data;
        this.dialogTitle = "修改视频信息";
        this.dialogVisible = true;
      });
    },
    //删除视频配置信息
    delHandle(id) {
      var ids = [];

      //批量删除并没有传入id
      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((row) => {
          ids.push(row.id);
        });
      }
      //console.log(ids)

      axios.post("/video/delete", ids).then((res) => {
        // console.log(res);
       ElMessage({
          showClose: true,
          message: "删除成功",
          type: "success",
          onClose: () => {},
        });
        this.getVideoList();
      });
    },
    // 提交相关信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.videoForm);
          //通过editForm判断是否存在id，有id就更新，没有id就执行存储
          axios
            .post(
              "/video/" + (this.videoForm.id ? "update" : "addVideoInfo"),
              this.videoForm
            )
            .then(() => {
              //console.log(res)
              ElMessage({
                showClose: true,
                message: "操作成功!",
                type: "success",
              });
              this.getVideoList();
              this.dialogVisible = false;
              this.resetForm(formName);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    videoSelectionChange(val) {
      //console.log(val)
      this.multipleSelection = val;
      this.delBtnStatu = val.length == 0;
      this.hasSelect = val.length;
    },
    videoSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      //改变页数后再次调用页面数据
      this.getVideoList();
    },
    videoCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.getVideoList();
    },
    //清空表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      //this.dialogVisible = false;
      this.videoForm = {};
    },
    handleClose() {
      this.resetForm("videoForm");
      this.dialogVisible = false;
      this.channelDlg = false;
    },
    handleClose1() {
      this.channelDlg = false;
    },
    //编辑视频；列表
    editChannel(id) {
      console.log("editChannel:", id);
      this.curVideoId = id;
      this.dialogTitle = "视频点配置";
      this.channelDlg = true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-tabs__header {
  margin: 0 0 5px;
}

/deep/.el-dialog__body {
  overflow: auto;
  background-color: white;
}
/deep/.el-dialog__header {
  background-color: white;
}
/deep/.el-dialog__title {
  color: black;
}
</style>
