<!-- 通道配置界面 -->
<template>
  <div v-show="isOPenDiv" style="height: 100%">
    <div style="height: 5px; background-color: #e9eef3"></div>
    <!-- 下 -->
    <div style="width: 100%; height: calc(100% - 5px)">
      <!-- 左列表 -->
      <div style="width: 20%; height: 100%; float: left" v-show="videoTreeVisible">
        <!-- 字体部分 -->
        <div style="height: 24px; background-color: #f6f7f9">
          <span style="margin-left: 10px; font-size: 16px; color: black">视频列表</span>
        </div>
        <!-- 列表部分 -->
        <div style="height: calc(70vh - 24px)">
          <el-button size="medium" type="success" style="margin-left: 10px; margin-top: 3px" @click="addVideoHandle1"><CirclePlus/> 添加组</el-button>
          <el-button size="medium" type="success" style="margin-left: 10px; margin-top: 3px" @click="addVideoHandle"><CirclePlus/> 添加视频</el-button>
          <!-- 设备列表部分 -->
          <div style="height: calc(70vh - 240px)">
            <el-tree style="margin-top: 3px; margin-left: 10px" :data="deviceTreeData" :props="defaultProps"
              default-expand-all @node-click="handleNodeClick">
               <template #default="{ node, data }">
                <span class="custom-tree-node ">
                <span>
                  <i v-if="data.indexCode !== ''" class="el-icon-video-camera"></i><i v-else class="el-icon-folder"></i>{{
                    node.label }}
                </span>
                <span>
                  <el-button v-if="data.indexCode !== ''" type="text" size="mini" style="margin-right: 3px"
                    @click="() => previewTree(node, data)">
                    <View/>
                  </el-button>
                  <el-button type="text" style="margin-right: 3px" size="mini" @click="() => updateTree(data, node)"><Edit/>
                  </el-button>
                  <el-popconfirm title="确定删除吗？" @confirm="deleteTree(data, node)">
                     <template #reference>
                    <el-button type="text" size="mini"><Delete/></el-button>
                     </template>
                  </el-popconfirm>
                </span>
                </span>
               </template>
            </el-tree>
          </div>
        </div>
      </div>
      <div style="width: 5px; height: 100%; float: left; background-color: #e9eef3" v-show="videoTreeVisible"></div>
      <!-- 下右 -->
      <div v-if="videoTreeVisible" style="width: calc(80% - 5px); height: 100%; float: left">
        <div ref="playWnd" id="playWnd" class="playWnd"></div>
      </div>
      <div v-else style="width: 100%; height: 100%; float: left">
        <!-- <div style="width: 100%; height: 2%; float: right">
            </div> -->
        <el-row style="height: 15px">
          <el-button style="float: right" size="mini" icon="el-icon-circle-close" @click="clickDivBtn"></el-button>
        </el-row>
        <div ref="playWnd" id="playWnd" class="playWnd" style="height: calc(100%-25px)" v-show="isOPenDiv"></div>
      </div>
    </div>
    <!--新增组-->
    <el-dialog :title= "isUpdateGroup ? '编辑组' : '添加组'" :append-to-body="true" v-model="channelDlgVisible" width="450px"
      :before-close="handleClose" :modal-append-to-body="false">
      <el-form :model="channelForm" :rules="channelFormRules" ref="channelForm" label-width="100px">
        <el-form-item label="组" prop="name" label-width="70px">
          <el-input v-model="channelForm.name" placeholder="请输入组名称"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="dsc" label-width="70px">
          <el-input v-model="channelForm.des" placeholder="请输入组描述"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 25px;text-align: left">
          <el-button type="primary" size="medium" @click="channelForm = {}"><Refresh/> 重置</el-button>
          <el-button type="primary" size="medium" @click="submitChannel('channelForm')"><CircleCheck/> 提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--新增设备-->
    <el-dialog title="添加摄像头" :append-to-body="true" v-model="videoDlgVisible" width="450px"
      :before-close="handleClose" :modal-append-to-body="false" style="z-index: 2032">
      <el-form :model="videoForm" :rules="videoFormRules" ref="videoForm" label-width="100px" size="medium">
        <el-form-item label="视频名称" prop="name">
          <el-input v-model="videoForm.name" placeholder="请输入视频名称"></el-input>
        </el-form-item>
        <el-form-item label="视频点编号" prop="indexCode">
          <el-input v-model="videoForm.indexCode" placeholder="请输入视频点编号"></el-input>
        </el-form-item>
        <el-form-item label="上级组" style="width: 100%" prop="parentId">
          <el-select v-model="videoForm.parentId" placeholder="请选择上级组:">
            <!--进行筛选，选择菜单-->
            <div v-for="(item, i) in channelOpt" :key="i">
              <el-option :label="item.name" :value="item.id"></el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type" label-width="100px">
          <el-select v-model="videoForm.type" placeholder="请选择类型:">
            <!--进行筛选，选择菜单-->
            <div v-for="(item, i) in cameraTypeOpt" :key="i">
              <el-option :label="item.name" :value="item.id"></el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="模型坐标x:" prop="x">
          <el-input v-model="videoForm.x" placeholder="请输入模型坐标x"></el-input>
        </el-form-item>
        <el-form-item label="模型坐标y:" prop="y">
          <el-input v-model="videoForm.y" placeholder="请输入模型坐标y"></el-input>
        </el-form-item>
        <el-form-item label="模型坐标z:" prop="z">
          <el-input v-model="videoForm.z" placeholder="请输入模型坐标z"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="dsc">
          <el-input v-model="videoForm.des" placeholder="请输入组描述"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 25px;text-align: left">
          <el-button type="primary" size="medium" @click="videoForm = {}"><Refresh/> 重置</el-button>
          <el-button type="primary" size="medium" @click="submitVideo('videoForm')"><CircleCheck/> 提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
</div>
</template>
  <script>
import axios from "@/http/HTTP";
import { ElMessage } from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
  export default {
    name: "ChannelCfg",
    props: {
      curVideoId: String,
    },
    data() {
      return {
        deviceTreeData: [],
        defaultProps: {
          children: "children",
          label: "name",
        },
        // 视频播放相关字段
        oWebControl: null,
        initCount: 0,
        pubKey: "",
        cameraIndexCode: "",
        videoConfig: {
          appkey: "25040958",
          secret: "ZoeKxTZ8FYGvQxiWHob8",
          ip: "192.168.1.10",
          port: 443,
        },
        isCloseVideoView: false,
        isOPenDiv: true,
        plugDlgVisible: false,
        videoTreeVisible: true, //显示或者隐藏摄像头列表树形结构
        channelOpt: [],
        //组字段
        channelDlgVisible: false,
        channelForm: {},
        channelFormRules: {
          name: [{ required: true, message: "请输入组名称", trigger: "blur" }],
        },
        isUpdateGroup:false,
        //视频字段
        videoDlgVisible: false,
        videoForm: {},
        videoFormRules: {
          name: [{ required: true, message: "请输入视频名称", trigger: "blur" }],
          indexCode: [
            { required: true, message: "请输入视频点编号", trigger: "blur" },
          ],
          parentId: [
            { required: true, message: "请选择上级组", trigger: "blur" },
          ],
          type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        },
        valueChangeTimer: null,
        lastValue: "",
        cameraTypeOpt: [
          {
            name: "枪机",
            id: "1",
          },
          {
            name: "球机",
            id: "2",
          },
        ],
      };
    },
    created() {
      console.log("created:", this.curVideoId);
      this.getVideoConfig();
      if (this.videoTreeVisible) {
        this.getDevTree();
      }
  
      this.valueChangeTimer = setInterval(() => {
        if (this.lastValue !== this.curVideoId) {
          console.log(
            "curVideoId:",
            this.curVideoId,
            "lastValue:",
            this.lastValue
          );
          this.getVideoConfig();
          if (this.videoTreeVisible) {
            this.getDevTree();
          }
          this.lastValue = this.curVideoId;
        }
      }, 300);
    },
    mounted() {
      let that = this;
      this.initPlugin();
      this.observeWrapper();
      $(window).resize(() => {
        if (this.oWebControl != null) {
          this.oWebControl.JS_Resize(1000, 600);
          this.setWndCover();
        }
      });
      $(window).scroll(() => {
        if (this.oWebControl != null) {
          this.oWebControl.JS_Resize(1000, 600);
          this.setWndCover();
        }
      });
    },
    unmounted() {
      console.log("des");
      if (this.oWebControl != null) {
        this.oWebControl.JS_HideWnd();
        this.oWebControl.JS_Disconnect().then(
          function () {},
          function () {}
        );
      }
      //curVideoId = "";
    },
    methods: {
      clickDivBtn() {
        this.isOPenDiv = false;
        console.log("clickDivBtn des");
        let that = this;
        if (this.oWebControl != null) {
          this.oWebControl.JS_HideWnd();
          that.isCloseVideoView = true;
        }
      },
      getDisplayFlag() {
        if (this.videoTreeVisible == false) return "display:none";
      },
  
      setCameraParam(cameraCode) {
        console.log("&&&&&& this.isCloseVideoView=", this.isCloseVideoView);
        if (this.isCloseVideoView == true) {
          this.oWebControl.JS_ShowWnd();
        }
        this.videoTreeVisible = false;
        this.videoDlgVisible = false;
        this.channelDlgVisible = false;
        this.isOPenDiv = true;
        console.log("&&&&&& cameraData.cameraCode:", cameraCode);
        var cameraIndexCode = cameraCode;
        var streamMode = 0;
        var transMode = 1;
        var gpuMode = 0;
        var wndId = -1;
  
        this.oWebControl.JS_RequestInterface({
          funcName: "startPreview",
          argument: JSON.stringify({
            cameraIndexCode: cameraIndexCode,
            streamMode: streamMode,
            transMode: transMode,
            gpuMode: gpuMode,
            wndId: wndId,
          }),
        });
      },
      // 提交通道名称
      submitChannel(formName) {
        this.channelForm.serverId = this.curVideoId;
        console.log("test " + this.channelForm.serverId);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //通过editForm判断是否存在id，有id就更新，没有id就执行存储
            // console.log(this.channelForm);
            axios
              .post(
                "/video/" +
                  (this.channelForm.id ? "updateChannel" : "saveChannel"),
                this.channelForm
              )
              .then(() => {
                //console.log(res)
                ElMessage({
                  showClose: true,
                  message: "操作成功!",
                  type: "success",
                });
                this.getDevTree();
                this.channelDlgVisible = false;
                this.initPlugin();
              });
          } else {
            console.log("error submit!!");
  
            return false;
          }
        });
      },
      submitVideo(formName) {
        this.videoForm.serverId = this.curVideoId;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //通过editForm判断是否存在id，有id就更新，没有id就执行存储
            axios
              .post(
                "/video/" + (this.videoForm.id ? "updateVideo" : "saveVideo"),
                this.videoForm
              )
              .then(() => {
                //console.log(res)
                ElMessage({
                  showClose: true,
                  message: "操作成功!",
                  type: "success",
                });
                this.getDevTree();
                this.videoDlgVisible = false;
                this.initPlugin();
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      destroyVideo() {
        if (this.oWebControl != null) {
          this.oWebControl.JS_HideWnd();
          this.oWebControl.JS_Disconnect().then(
            function () {},
            function () {}
          );
        }
      },
      getDevTree() {
        axios
          .get("/video/getNewVideoTree/" + this.curVideoId)
          .then((res) => {
            console.log("/video/getNewVideoTree:", res.data);
            this.deviceTreeData = res.data;
            console.log("*****getDevTree", res.data);
          });
      },
      addVideoHandle() {
        this.destroyVideo();
        this.videoDlgVisible = true;
        axios.get("/video/getChannel/" + this.curVideoId).then((res) => {
          console.log(res.data);
          this.channelOpt = res.data;
        });
        this.getDevTree();
      },
      addVideoHandle1() {
        this.destroyVideo();
        this.channelDlgVisible = true;
        this.isUpdateGroup = false;
      },
      handleClose() {
        this.videoDlgVisible = false;
        this.channelDlgVisible = false;
        this.channelForm = {};
        this.videoForm = {};
        this.initPlugin();
      },
      updateTree(data, node) {
        this.destroyVideo();
        if (node.level == 1) {
          //当前为组
          this.channelForm = data;
          this.channelDlgVisible = true;
          this.isUpdateGroup = true;
          // console.log(this.channelForm);
        } else {
          //当前为视频点位
          this.videoForm = data;
          this.videoForm.parentId = data.parentId;
          this.addVideoHandle();
        }
        this.getDevTree();
      },
      deleteTree(data) {
        console.log("deleteTree:", data.id);
        var childrens = data.children;
        if (!childrens.length == 0) {
          ElMessage({
            showClose: true,
            message: "该组织下有子组织，请先删除子组织!",
            type: "warning",
          });
        } else {
          axios.post("/video/deleteChannel/" + data.id).then(() => {
           ElMessage({
              showClose: true,
              message: "操作成功!",
              type: "success",
            });
            this.getDevTree();
          });
        }
      },
      previewTree(node, data) {
        console.log("&&&cameraCode:", data.indexCode);
  
        var cameraIndexCode = data.indexCode;
        var streamMode = 0;
        var transMode = 1;
        var gpuMode = 0;
        var wndId = -1;
        this.oWebControl.JS_RequestInterface({
          funcName: "startPreview",
          argument: JSON.stringify({
            cameraIndexCode: cameraIndexCode,
            streamMode: streamMode,
            transMode: transMode,
            gpuMode: gpuMode,
            wndId: wndId,
          }),
        });
      },
      handleNodeClick() {},
      init() {
        let that = this;
        console.log("*******init:", that.videoConfig);
        this.getPubKey(function () {
          var appkey = that.videoConfig.appkey; //请自行修改为你自己的
          var secret = that.setEncrypt(that.videoConfig.secret); //请自行修改为你自己的
          var ip = that.videoConfig.ip; //请自行修改为你自己的
          var playMode = 0; //这个是播放模式，0是预览，1是回放
          var port = that.videoConfig.port; //请自行修改为你自己的
          var snapDir = "D:\\3DSnapDir";
          var videoDir = "D:\\3DVideoDir";
          var layout = "1x1";
          var enableHTTPS = 1;
          var encryptedFields = "secret";
          var showToolbar = 1;
          var showSmart = 1;
          var buttonIDs =
            "0,16,256,257,258,259,260,512,513,514,515,516,517,768,769";
          that.oWebControl
            .JS_RequestInterface({
              funcName: "init",
              argument: JSON.stringify({
                appkey: appkey,
                secret: secret,
                ip: ip,
                playMode: playMode,
                port: port,
                snapDir: snapDir,
                videoDir: videoDir,
                layout: layout,
                enableHTTPS: enableHTTPS,
                encryptedFields: encryptedFields,
                showToolbar: showToolbar,
                showSmart: showSmart,
                buttonIDs: buttonIDs,
              }),
            })
            .then(function (oData) {
              that.oWebControl.JS_Resize(
                that.$refs.playWnd.offsetWidth,
                that.$refs.playWnd.offsetHeight
              );
            });
        });
      },
      //推送消息
      cbIntegrationCallBack(oData) {
        // this.$message(JSON.stringify(oData.responseMsg));
      },
  
      //监听自身容器大小变化
      observeWrapper() {
        const ro = new ResizeObserver((entries) => {
          for (const entry of entries) {
            const cr = entry.contentRect;
            this.videoWidth = cr.width;
            this.videoHeight = cr.height;
            this.oWebControl &&
              this.oWebControl.JS_Resize(this.videoWidth, this.videoHeight);
            this.oWebControl && this.setWndCover();
          }
        });
        ro.observe(document.querySelector("#playWnd"));
      },
      setWndCover() {
        //裁剪插件实例的大小
        let iWidth = $(window).width();
        let iHeight = $(window).height();
        let oDivRect = $("#playWnd").get(0).getBoundingClientRect();
  
        let iCoverLeft = oDivRect.left < 0 ? Math.abs(oDivRect.left) : 0;
        let iCoverTop = oDivRect.top < 0 ? Math.abs(oDivRect.top) : 0;
        let iCoverRight =
          oDivRect.right - iWidth > 0 ? Math.round(oDivRect.right - iWidth) : 0;
        let iCoverBottom =
          oDivRect.bottom - iHeight > 0
            ? Math.round(oDivRect.bottom - iHeight)
            : 0;
        iCoverLeft = iCoverLeft > this.videoWidth ? this.videoWidth : iCoverLeft;
        iCoverTop = iCoverTop > this.videoHeight ? this.videoHeight : iCoverTop;
        iCoverRight =
          iCoverRight > this.videoWidth ? this.videoWidth : iCoverRight;
        iCoverBottom =
          iCoverBottom > this.videoHeight ? this.videoHeight : iCoverBottom;
        this.oWebControl.JS_RepairPartWindow(0, 0, 1001, 600);
        if (iCoverLeft != 0) {
          this.oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, 600);
        }
        if (iCoverTop != 0) {
          this.oWebControl.JS_CuttingPartWindow(0, 0, 1001, iCoverTop);
        }
        if (iCoverRight != 0) {
          this.oWebControl.JS_CuttingPartWindow(
            1000 - iCoverRight,
            0,
            iCoverRight,
            600
          );
        }
        if (iCoverBottom != 0) {
          this.oWebControl.JS_CuttingPartWindow(
            0,
            600 - iCoverBottom,
            1000,
            iCoverBottom
          );
        }
      },
      initPlugin() {
        // console.log(123);
        let that = this;
        this.oWebControl = new WebControl({
          szPluginContainer: "playWnd", // 指定容器id
          iServicePortStart: 15900, // 指定起止端口号，建议使用该值
          iServicePortEnd: 15909,
          szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11", // 用于IE10使用ActiveX的clsid
          cbConnectSuccess: function () {
            that.oWebControl
              .JS_StartService("window", {
                // WebControl实例创建成功后需要启动服务
                dllPath: "./VideoPluginConnect.dll", // 值"./VideoPluginConnect.dll"写死
              })
              .then(
                function () {
                  console.log(
                    "&&&success:",
                    that.$refs.playWnd.offsetWidth,
                    that.$refs.playWnd.offsetHeight
                  );
                  that.oWebControl.JS_SetWindowControlCallback({
                    // 设置消息回调
                    cbIntegrationCallBack: that.cbIntegrationCallBack,
                  });
                  that.oWebControl
                    .JS_CreateWnd(
                      "playWnd",
                      that.$refs.playWnd.offsetWidth,
                      that.$refs.playWnd.offsetHeight
                    ) //JS_CreateWnd创建视频播放窗口，宽高可设定
                    .then(function () {
                      //console.log("成功222", that);
                      that.init(); // 创建播放实例成功后初始化
                    });
                },
                function () {
                  // 启动插件服务失败
                  console.log("fail");
                }
              );
          },
          cbConnectError: function () {
            // 创建WebControl实例失败
            console.log(that, "that");
            that.oWebControl = null;
            that.$message.error("插件未启动，正在尝试启动，请稍候...");
            WebControl.JS_WakeUp("VideoWebPlugin://"); // 程序未启动时执行error函数，采用wakeup来启动程序
            that.initCount++;
            if (that.initCount < 3) {
              setTimeout(function () {
                that.initPlugin();
              }, 3000);
            } else {
              that.$message.error("插件启动失败，请检查插件是否安装！");
              that.plugDlgVisible = true;
              that.$emit("showIntallPlugEvent");
            }
          },
          cbConnectClose: function (bNormalClose) {
            // 异常断开：bNormalClose = false
            // JS_Disconnect正常断开：bNormalClose = true
            console.log("cbConnectClose");
            that.oWebControl = null;
          },
        });
      },
  
      //公钥的获取
      getPubKey(callback) {
        let that = this;
        this.oWebControl
          .JS_RequestInterface({
            funcName: "getRSAPubKey",
            argument: JSON.stringify({
              keyLength: 1024,
            }),
          })
          .then(function (oData) {
            //console.log(oData);
            if (oData.responseMsg.data) {
              that.pubKey = oData.responseMsg.data;
              callback();
            }
          });
      },
      setEncrypt(value) {
        let encrypt = new JSEncrypt();
        encrypt.setPublicKey(this.pubKey);
        return encrypt.encrypt(value);
      },
  
      startClickFn() {
        var cameraIndexCode = this.cameraIndexCode;
        var streamMode = 0;
        var transMode = 1;
        var gpuMode = 0;
        var wndId = -1;
        this.oWebControl.JS_RequestInterface({
          funcName: "startPreview",
          argument: JSON.stringify({
            cameraIndexCode: cameraIndexCode,
            streamMode: streamMode,
            transMode: transMode,
            gpuMode: gpuMode,
            wndId: wndId,
          }),
        });
      },
      stopClickFn() {
        if (this.oWebControl && this.oWebControl.JS_RequestInterface) {
          this.oWebControl.JS_RequestInterface({
            funcName: "stopAllPreview",
          });
        }
      },
      getVideoConfig() {
        axios.get("/video/info/" + this.curVideoId).then((res) => {
          console.log("#####getVideoConfig:", res);
          this.videoConfig.appkey = res.data.appKey;
          this.videoConfig.secret = res.data.appSecret;
          this.videoConfig.ip = res.data.videoIp;
          this.videoConfig.port = Number(res.data.videoPort);
        });
      },
    },
  };
  </script>
  
<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.playWnd {
  width: 99.8%;
  /*播放容器的宽和高设定*/
  height: 99.7%;
  border: 1px solid red;
  display: flex;
}

/* .operate {
    padding-top: 10px;
  }
  
  .operate::after {
    content: "";
    display: block;
    clear: both;
  } */

.module {
  float: left;
  width: 340px;
  /*min-height: 320px;*/
  margin-left: 16px;
  padding: 16px 8px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
}

.module .item {
  margin-bottom: 4px;
}

.module input[type="text"] {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
  width: 150px;
  min-height: 20px;
}

.module .btn {
  min-width: 80px;
  min-height: 24px;
  margin-top: 100px;
  margin-left: 80px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/ .el-dialog__body {
  height: 40vh;
  overflow: auto;
  background-color: white;
}

/deep/ .el-dialog__header {
  background-color: white;
}

/deep/ .el-dialog__title {
  color: black;
}

</style>
  